<script>


import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import StateLogTab from './components/tab_state_log.vue'
import BreakdownTab from './components/tab_statement.vue'
import StatementTab from './components/tab_statement_records.vue'
import FolderTab from './components/tab_folder.vue'

import DepositRentDialog from './components/deposit_rent_dialog.vue'


import appConfig from "@/app.config";
import { getPMApi } from '@/api/pm'

/**
 * Project-overview component
 */
export default {
  page: {
    title: "Property Overview",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Property Overview",
      items: [
      {
          text: "PM",
          href: "/",
        },
        {
          text: "RTA List",
          href: "/pm/property_list",
        },
        {
          text: 'Detail',
          active: true,
        },
      
      ],

      property: {


      },


    };
  },
  components: {
    Layout,
    PageHeader,

    BreakdownTab,
    StateLogTab,
    StatementTab,
    FolderTab,


    DepositRentDialog


  },

  computed: {
    /**
     * Total no. of records
     */
    pm_code() {
      return this.$route.query.pm_id
    },
  },

  methods: {

    fileDownload(f) {
      window.open(f.save_route, '_blank');
    },

    pm_deposit_rent(rent_info) {
      console.log(rent_info)
    },

    add_expense() {
      this.$router.push({name : 'pm-batch-expense', query: {property_id : this.property.property_id}})
    },

    post_ap() {
      this.$router.push({name : 'pm-property-batch-pm-ap', query: {property_id : this.property.property_id}})
    },

    deposit_rent(t) {
      if (t == 'PAD') {
        this.$router.push({name : 'pm-rent-deposit-pad', query: {property_id : this.property.property_id}})
      } else if (t == 'Cheque') {
        this.$router.push({name : 'pm-rent-deposit-cheque', query: {property_id : this.property.property_id}})
      } else if (t == 'ETransfer') {
        this.$router.push({name : 'pm-rent-deposit-etransfer', query: {property_id : this.property.property_id}})
      }
    }


  },



  created() {


    getPMApi().detail({ pm_id: this.$route.query.pm_id }).then((res) => {
     
      if (res.errCode == 0) {
        this.property = res.data
        
      }

    })

  },
  mounted() {

  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card mb-0">
          <div class="card-body pb-5">
            <div class="row">
              <div class="col">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <div class="avatar">
                      <div class="avatar-title bg-soft-primary text-primary font-size-18 rounded">
                        PM
                      </div>
                    </div>
                  </div>
                  <div class=" ms-4">
                    <div class="text-muted">
                      <h5 class="font-size-16 mb-2">{{ property.property_id }}</h5>
                      <h5 class="font-size-16 mb-2">{{ property.address }}</h5>

                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->

              <div class="col-auto">
                <div class="d-flex flex-wrap align-items-start justify-content-md-end gap-2 mb-3">

                 <b-dropdown variant="primary">
                  <template slot="button-content">
                    Deposit Rent
                    <i class="mdi mdi-chevron-down"></i>
                  </template>
                  <b-dropdown-item @click="deposit_rent('PAD')">PAD</b-dropdown-item>
                  <b-dropdown-item @click="deposit_rent('Cheque')">Cheque</b-dropdown-item>
                  <b-dropdown-item @click="deposit_rent('ETransfer')">ETransfer</b-dropdown-item>
                </b-dropdown>
                <b-button type="button" variant="secondary" @click="post_ap">Post AP</b-button>
                <b-button type="button" variant="secondary" @click="add_expense">Add Expense</b-button>
                <b-button type="button" variant="danger" @click="$router.push({name : 'pm-property-deposit-release', query : {pm_id : property.property_id}})">Deposit Release</b-button>
 <!-- 
                  <b-dropdown variant="link" toggle-class="shadow-none text-dark" right>
                    <template v-slot:button-content>
                      <i class="uil uil-ellipsis-h"></i>
                    </template>
                    <li><b-dropdown-item v-b-modal.modal-basic-info-edit-dialog> Edit Basic Info</b-dropdown-item></li>
                  </b-dropdown> -->
                  <!--                    
                    <b-modal centerd id="modal-basic-info-edit-dialog" title="Basic Info" size="lg" hide-footer>
                      <BascInfoEditDialog :deal="deal" @cancel="$bvModal.hide('modal-basic-info-edit-dialog')" @confirm="deal_basic_save"  />
                    </b-modal>
                    -->

                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
            <b-modal centerd id="modal-deposit-rent-dialog" title="Deposit Rent" size="lg" hide-footer>
              <DepositRentDialog :property="property" @cancel="$bvModal.hide('modal-deposit-rent-dialog')" @confirm="pm_deposit_rent"  />
            </b-modal>
            

            <div class="row">
              <div class="col-lg-5">
                <div class="mt-3">
                  <ul class="text-muted">
                    <li class="py-1" >Available Balance : {{ property.balance_available?property.balance_available.toLocaleString() :0 }}</li>
                    <li class="py-1" >Secure Deposit : {{ property.deposit?property.deposit.toLocaleString():0 }}</li>
                    <li class="py-1" >Rent in Hold : {{ property.rent_in_hold?property.rent_in_hold.toLocaleString() :0 }}</li>
                  
                    <li class="py-1" v-for="(a, idx) in property.agents" :key="a.id+'_'+idx">Agent : {{ a.name }}</li>
                    <li class="py-1" v-for="(a, idx) in property.landlords" :key="a.id+'_'+idx">Landlord : {{ a.landlord_name }}</li>
                    <li class="py-1" v-for="(a, idx) in property.tenants" :key="a.id+'_'+idx">Tenant : {{ a.name }}</li>
                  </ul>
                </div>
              </div>
              <!-- end col -->

              <div class="col-lg-7">
                <div class="row">
                  <div class="col-lg-3 col-sm-6">
                    <div class="d-flex align-items-center mt-4">
                        <div class="flex-shrink-0 me-3">
                          <i
                            class="uil uil-check-circle text-primary font-size-22"
                          ></i>
                        </div>
                        <div class="flex-grow-1">
                          <h5 class="font-size-12 mb-1">Rent</h5>
                          <p class="text-muted mb-0">${{ Number(property.monthly_rent).toLocaleString() }}</p>
                        </div>
                      </div>
                  </div>
                  
                   
                  <div class="col-lg-3 col-sm-6">
                    <div class="d-flex align-items-center mt-4">
                      <div class="flex-shrink-0 me-3">
                        <i class="uil uil-check-circle text-primary font-size-22"></i>
                      </div>
                      <div class="flex-grow-1">
                        <h5 class="font-size-12 mb-1">Utilities</h5>
                        <p class="text-muted mb-0">${{ Number(property.utility_fee).toLocaleString() }}</p>
                      </div>
                    </div>
                  </div> 

                  <div class="col-lg-3 col-sm-6">
                    <div class="d-flex align-items-center mt-4">
                      <div class="flex-shrink-0 me-3">
                        <i class="uil uil-check-circle text-primary font-size-22"></i>
                      </div>
                      <div class="flex-grow-1">
                        <h5 class="font-size-12 mb-1">Parking</h5>
                        <p class="text-muted mb-0">${{ Number(property.parking).toLocaleString() }}</p>
                      </div>
                    </div>
                  </div> 


                  <div class="col-lg-3 col-sm-6">
                    <div class="d-flex align-items-center mt-4">
                      <div class="flex-shrink-0 me-3">
                        <i class="uil uil-check-circle text-primary font-size-22"></i>
                      </div>
                      <div class="flex-grow-1">
                        <h5 class="font-size-12 mb-1">Other</h5>
                        <p class="text-muted mb-0">${{ Number(property.other_income).toLocaleString() }}</p>
                      </div>
                    </div>
                  </div> 
                </div>
                <!-- end row -->

                <div class="row">
                  <div class="col-lg-4 col-sm-4">
                    <div class="d-flex align-items-center mt-4">
                      <div class="flex-shrink-0 me-3">
                        <i class="uil uil-calendar-alt text-primary font-size-22"></i>
                      </div>
                      <div class="flex-grow-1">
                        <h5 class="font-size-12 mb-1">Start Date</h5>
                        <p class="text-muted mb-0">{{ property.start_date }}</p>
                      </div>
                    </div>
                  </div>
                  <!-- end col -->


                </div>
                <!-- end row -->
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- en card -->
        <b-tabs class="mt-n5 pt-2" content-class="card card-body mt-3" nav-class="nav-tabs-custom"
          nav-wrapper-class="nav-tabs-custom">


          
          <b-tab title="Break Down">
            <BreakdownTab :property="property" />
          </b-tab>
  
       
          <b-tab title="Statement">
            <StatementTab :property="property" />
          </b-tab>
          <b-tab title="Files">
            <FolderTab  />
          </b-tab>
          
          
          <b-tab title="State Log">
            <StateLogTab :property="property" />
          </b-tab>


          
        </b-tabs>
        <!-- Nav tabs -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
